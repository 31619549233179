@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: Helvetica;
  src: url("./fonts/Helvetica.ttf");
}
@font-face {
  font-family: GRIFTER;
  src: url("./fonts/GRIFTER\ Bold\ 700.otf");
}
@font-face {
  font-family: Gilroy;
  src: url("./fonts/Radomir\ Tinkov\ -\ Gilroy-ExtraBold.otf");
}
@font-face {
  font-family: Montserrat;
  src: url("./fonts/Montserrat-Light.ttf");
}
@font-face {
  font-family: Quicksand_Light;
  src: url("./fonts/Quicksand_Light.otf");
}
#react-select-3-placeholder,#react-select-2-placeholder{
  font-size: 16px;
  color: white;
}
html {
  scroll-behavior: smooth;
  width: 100%;
  max-width:100vw;
  overflow-x: hidden;
}
// body{
//   overflow: hidden;
// }

.shadow {
  box-shadow: #fdd92a 0px 0px 20px 0px;
}

/* 3D image  */
.card {
  box-shadow: 0 1px 5px #00000099;
  border-radius: 10px;
  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
}
.card:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}
.card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}

/* effect-shine */
.logo-shine {
  mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  animation: shine 2s infinite;
}
/* effect-shine */
.effect-shine:hover {
  mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  animation: shine 2s infinite;
}
.effect-shine-text:hover p {
  mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  animation: shine 2s infinite;
}
// .button__box {
//   transition: transform 0.2s linear;
// }
// .button__box span {
//   pointer-events: none;
//   transition: transform 0.2s linear;
//   display: flex;
// }
@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

/* g[clip-path^="url(#__lottie_element_"]{
  transform: scale(1.5) translate(-370px,-200px);
} */
.CardMain {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  .perspective {
    width: 100%;
    perspective: 1000px;
    .card {
      // width: 270px;
      // height: 413px;
      margin: auto;
      transform-style: preserve-3d;
      transition: transform 0.05s linear;

      .thumb {
        background-size: cover;
        height: 100%;
        width: 100%;
        border-radius: 15px;

        &:after {
          background: inherit;
          content: "";
          display: block;
          position: absolute;
          left: -60px;
          top: 40px;
          width: 100%;
          height: 108%;
          z-index: -1;
          filter: blur(55px);
        }
      }
    }
  }
}

.css-13cymwt-control,
.css-t3ipsp-control {
  &,
  &:focus {
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important;
    height: 56px !important;
    border-radius: 0px !important;
  }
  .css-1fdsijx-ValueContainer {
    &:focus-visible {
      border: none !important;
    }
  }
  .css-1u9des2-indicatorSeparator {
    background-color: transparent !important;
  }
}
// .css-1nmdiq5-menu {
//   background-color: rgba(255, 255, 255, 0.1) !important;
// }

.listOfSelect {
  & > div[tabindex] {
    // background-color: rgba(255, 255, 255, 0) !important;
    color: #000;
    &:hover,
    &:focus {
      color: #000;
      // background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
}
.css-1p3m7a8-multiValue {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  div {
    color: white !important;
  }
}
.btn {
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all 0.2s;
    position: absolute;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
    background-color: #fff;

  }
  &:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
  &.btn-white {
    background-color: #fff;
    color: #777;
    &::after {
      background-color: #fff;
    }
  }
  &.btn-yellow {
    // background-color: #FDD92A;
    &::after {
      background-color: #FDD92A;
    }
  }
  &.btn-animated {
    animation: moveInBottom 5s ease-out;
    animation-fill-mode: backwards;
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
